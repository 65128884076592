import axios from '@axios'
import baseConfig from '@/config/index'
const AUTH_SERVICE_PATH = baseConfig.AUTH_SERVICE_PATH
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/detail', { username: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserProfile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/info', {  })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrganizations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/organization/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTeamsByFilter(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/listByFilter', teamData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAccountSetting(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/edit', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePasswordAccountSetting(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/changePassword', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
